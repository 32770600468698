import React, {useEffect} from 'react'
import "./index.css"
import GlobalSnackbar from "../../view/globalSnackbar/GlobalSnackbar";
import service from "../../../config/request";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer, {tableContainerClasses} from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {styled} from "@mui/material/styles";
import dayjs from "dayjs";
import PageView from "../../view/PageView/PageView";
import {formatReward} from "../../../utils/common";
import {ETHWSAN} from "../../../config/config";


const Blocks = () => {
    const {t} = useTranslation();
    const [blocksData, setBlocksData] = React.useState(null);
    const {search} = useLocation();
    const [blockList, setBlockList] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    // const [luckTime, setLuckTime] = React.useState(0);
    const rowsPerPage = 10;
    useEffect(() => {
        function getData() {
            service({
                url: '/blocks',
                method: 'get',
            }).then(response => {
                var concat = [];
                if (response.candidates) {
                    for (let i = 0; i < response.candidates.length; i++) {
                        response.candidates[i]['status'] = 'New';
                    }
                    concat = concat.concat(response.candidates)
                }
                if (response.immature) {
                    for (let i = 0; i < response.immature.length; i++) {
                        response.immature[i]['status'] = 'Pending';
                    }
                    concat = concat.concat(response.immature)
                }
                if (response.matured) {
                    for (let i = 0; i < response.matured.length; i++) {
                        response.matured[i]['status'] = 'Confirmed';
                    }
                    concat = concat.concat(response.matured)
                }
                response.blocks = concat;
                setBlocksData(response)
                setPage(1)
                var num = concat.length;
                var remainder = num % rowsPerPage;
                var pages = num / rowsPerPage;
                if (remainder > 0) {
                    pages = pages + 1;
                }
                setPages(pages)
                if (num > rowsPerPage) {
                    setBlockList(response.blocks.slice(0, rowsPerPage))
                } else {
                    setBlockList(response.blocks)
                }

            }).catch(error => {
                GlobalSnackbar.showMessage(error.message, 'error')
            })
        }
        // function getStats() {
        //     service({
        //         url: '/stats',
        //         measure: 'get',
        //     }).then(response => {
        //         const netSpace=parseFloat(response.nodes[0].networkhashps)
        //         const poolnetSpace=parseFloat(response.hashrate)
        //         const luckTime= parseFloat(1)/((poolnetSpace/netSpace)*parseFloat(32/10))*60;
        //         setLuckTime(luckTime);
        //     }).catch(error => {
        //         GlobalSnackbar.showMessage(error.message, 'error')
        //     })
        // }
        //
        // getStats();
        getData();
    }, [search]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleChangePage = (newPage
    ) => {
        setPage(newPage)
        setBlockList(blocksData.blocks.slice((newPage - 1) * rowsPerPage, newPage * rowsPerPage))
    };


    const isHref = (hashString, height) => {
        if (hashString === "") {
            return height;
        } else {
            return <a className={'text-monospace'}
                      target="_blank"
                      rel="noreferrer"
                      href={ETHWSAN + hashString}>
                {height}
            </a>
        }
    };

    const isHrefHash = (hashString) => {
        if (hashString === "") {
            return '-----';
        } else {
            var sliceStart = hashString.slice(0, 15);
            var sliceEnd = hashString.slice(hashString.length - 15);
            return <a className={'text-monospace'}
                      style={{fontFamily: 'Consolas,Monaco,monospace'}}
                      target="_blank"
                      rel="noreferrer"
                      href={ETHWSAN + hashString}>
                {sliceStart + "..." + sliceEnd}
            </a>
        }
    };
    const getLuck = (index,indexBefore) => {
       // const indexX = index+(page-1)*rowsPerPage;
      //  const indexBeforeX = indexBefore+(page-1)*rowsPerPage;
      // if (indexBeforeX>=blocksData.blocks.length){
      //     return NaN;
      // }
        const luck = parseFloat(blockList[index].shares / blockList[index].difficulty * 100).toFixed(2)
        return luck;
    }
    return (
        <div className={'mainLy'}>
            <div className={'container'} style={{marginTop: '20px'}}>
                {
                    blocksData &&
                    <div className={'row'}>
                        <div className={'borderColor mb-2'}>
                            <div className={'row'}>
                                <div className={'col-3 borderDash_Right'}>
                                    <div className={'padding-2 td_title'} style={{textAlign: 'center'}}>
                                        {t('BLOCKS')}
                                    </div>
                                </div>
                                <div className={'col-3 borderDash_Right'}>
                                    <div className={'padding-2 td_title'} style={{textAlign: 'center'}}>
                                        {t('LUCK')}
                                    </div>
                                </div>
                                <div className={'col-3 borderDash_Right'}>
                                    <div className={'padding-2 td_title'} style={{textAlign: 'center'}}>
                                        {t('UNCLE')}
                                    </div>
                                </div>
                                <div className={'col-3'}>
                                    <div className={'padding-2 td_title'} style={{textAlign: 'center'}}>
                                        {t('ORPHAN')}
                                    </div>
                                </div>
                            </div>
                            {
                                Object.entries(blocksData.luck).map((row, index) => (
                                    <div className={'row borderDash_Top'} key={index}>
                                        <div className={'col-3 borderDash_Right'}>
                                            <div className={'padding-2 td_title'} style={{textAlign: 'center'}}>
                                                {row[0]}
                                            </div>
                                        </div>
                                        <div className={'col-3 borderDash_Right'}>
                                            <div className={'padding-2 td_title'}
                                                 style={{textAlign: 'center', color: 'var(--table-color-blue)'}}>
                                                {Math.round(row[1].luck * 100)}%
                                            </div>
                                        </div>
                                        <div className={'col-3 borderDash_Right'}>
                                            <div className={'padding-2 td_title'}
                                                 style={{textAlign: 'center', color: 'var(---table-color-yellow)'}}>
                                                {Math.round(row[1].uncleRate * 100)}%
                                            </div>
                                        </div>
                                        <div className={'col-3'}>
                                            <div className={'padding-2 td_title'}
                                                 style={{textAlign: 'center', color: 'var(--table-color-green)'}}>
                                                {Math.round(row[1].orphanRate * 100)}%
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <StyledTableContainer className={'borderColor mb-2'}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">{t('HEIGHT')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('TYPE')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('TIME')}</StyledTableCell>
                                        {/*<StyledTableCell align="left">{t('SERVER')}</StyledTableCell>*/}
                                        <StyledTableCell align="left">{t('HASH')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('STATUS')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('LUCK')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('REWARD')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {blockList && blockList.map((row, index) => (
                                            <TableRow key={index}>
                                                <StyledTableCell>
                                                    {isHref(row.hash, row.height)}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{t('Block')}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <div style={{
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis"
                                                    }}>{getDayjsYMDHM(row.timestamp)} </div>
                                                </StyledTableCell>
                                                {/*<StyledTableCell align="left">{row.server}</StyledTableCell>*/}
                                                <StyledTableCell align="left">{isHrefHash(row.hash)}</StyledTableCell>
                                                <StyledTableCell align="left">{row.status}</StyledTableCell>
                                                <StyledTableCellGreen
                                                    align="left">{getLuck(index,index+1)}%</StyledTableCellGreen>
                                                <StyledTableCell
                                                    align="left">{formatReward(row.reward)}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            <div className={'v-data-footer'}>
                                <PageView
                                    pageNum={page}
                                    pages={pages}
                                    setPage={handleChangePage}
                                ></PageView>
                            </div>
                        </StyledTableContainer>
                    </div>
                }
            </div>
            <GlobalSnackbar/>
        </div>
    );
};

export const getDayjsYMDHM = (timestamp) => {
    return dayjs(timestamp * 1000).format("YYYY/MM/DD HH:mm")
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'var(--text-color)',
        fontWeight: 'bold',
        fontSize: 14,
        borderColor: 'var(--divide1-color)',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'var(--text-color)',
        borderColor: 'var(--divide1-color)',

    },
}));


const StyledTableCellGreen = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'var(--table-color-green)',
        borderColor: 'var(--divide1-color)',
    },
}));


const StyledTableContainer = styled(TableContainer)(({theme}) => ({
    [`&.${tableContainerClasses.root}`]: {
        backgroundColor: 'transparent',
        borderRadius: 0,
    },
}));

// const StyledTablePagination = styled(TablePagination)(({theme}) => ({
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//         color: 'var(--table-color-green)',
//         borderColor: 'var(--divide1-color)',
//         borderBottom:0
//     },
//     [`&.${tablePaginationClasses.root}`]: {
//         color: 'var(--title-color)',
//         borderColor: 'transparent',
//         alignItems:'center'
//     },
//     [`&.${tablePaginationClasses.selectRoot}`]: {
//         backgroundColor: 'var(--title-color)',
//         color: 'var(--title-color)',
//         borderColor: 'transparent'
//     },
// }));
export default Blocks;
