import React, {Component} from 'react'
import {Alert, Snackbar} from '@mui/material';

export default class GlobalSnackbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            severity: "info",
            message: "",
            vertical: 'top',
            horizontal: 'center',
        }
    }

    static showMessage = (msg, type = 'info') => {
        console.error("Not Implemented.", msg, type)
    }

    componentDidMount() {
        GlobalSnackbar.showMessage = (msg, type = 'info') => {
            this.setState({
                isOpen: true, severity: type,
                message: msg
            })
        }
    }

    handleClose = (_, reason) => {
        if (reason === 'clickaway') return;
        this.setState({isOpen: false})
    }

    render() {
        let {isOpen, severity, message,vertical,horizontal} = this.state;
        return (
            <Snackbar open={isOpen} autoHideDuration={4000} onClose={this.handleClose}  anchorOrigin={{ vertical, horizontal }}>
                <Alert  icon={false} onClose={this.handleClose} severity={severity} sx={{width: '100%'}} elevation={6}>
                    {message}
                </Alert>
            </Snackbar>
        )
    }
}
