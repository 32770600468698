import React, {useEffect, useRef} from 'react'
import "./index.css"
import GlobalSnackbar from "../../view/globalSnackbar/GlobalSnackbar";
import service from "../../../config/request";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {beautifyTime, diffTime, getDagSize, getDayjsYMDHMS, hashrate_formatter} from "../../../utils/common";
import {HOSTPORTMap} from "../../../config/config";

const echarts = require("echarts")

const Home = ({isDark}) => {
    const {t} = useTranslation();
    const [homeData, setHomeData] = React.useState(null);
    const [chartData, setChartData] = React.useState(null);
    const {search} = useLocation();
    const dom = useRef();
    useEffect(() => {
        function getData() {
            service({
                url: '/stats',
                method: 'get',
            }).then(response => {
                const netSpace=parseFloat(response.nodes[0].networkhashps)
                const poolnetSpace=parseFloat(response.hashrate)
                const luckTime= parseFloat(1)/((poolnetSpace/netSpace)*parseFloat(32/10))*60
                const slotTime =diffTime(response.stats.lastBlockFound)
                console.log(netSpace,poolnetSpace,luckTime,slotTime)
                const luck = parseFloat(luckTime / slotTime * 100).toFixed(2)
                response.luck = luck;
                setHomeData(response)
            }).catch(error => {
                GlobalSnackbar.showMessage(error.message, 'error')
            })
        }

        function getChart() {
            service({
                url: '/chart',
                method: 'get',
            }).then(response => {
                setChartData(response)
            }).catch(error => {
                GlobalSnackbar.showMessage(error.message, 'error')
            })
        }

        getData();
        getChart();
    }, [search]);// eslint-disable-line react-hooks/exhaustive-deps
    const initChart = () => {
        if (!chartData){
            return
        }
        if (!dom.current) {
            dom.current = echarts.init(document.getElementById('chart1'));
        }
        const date = [];
        const hashrates = [];
        const difficultys = [];
        let maxHashrate = 0;
        let maxDifficulty = 0;
        if (chartData.pool){
            for (let i = 0; i < chartData.pool.length; i++) {
                date.push(chartData.pool[i].x);
                const pool=parseFloat(parseFloat(chartData.pool[i].y/1000000000).toFixed(2));
                hashrates.push(pool);
                if (maxHashrate<pool){
                    maxHashrate=pool;
                }
            }
        }
        if (chartData.diff){
            for (let i = 0; i < chartData.diff.length; i++) {
                const difficulty=parseFloat(parseFloat(chartData.diff[i].y/1000000000).toFixed(2));
                difficultys.push( difficulty);
                if (maxDifficulty<difficulty){
                    maxDifficulty=difficulty;
                }
            }
        }
        const interval_left = Math.ceil(maxHashrate / 5);
        const interval_right = Math.ceil(maxDifficulty / 5);
        window.addEventListener("resize", () => { dom.current.resize(); });
        dom.current.clear();
        dom.current.setOption({
            tooltip:{
                trigger:'axis',
                formatter: function (params) {
                    var showHtm = getDayjsYMDHMS(params[0].name) + "<br />";
                    for (var i = 0; i < params.length; i++) {
                        //名称
                        var name = params[i].seriesName;
                        //值
                        var value = params[i].value+ (i===1?" G":" GH/s");
                        //颜色
                        var color = '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:' + params[i].color + '"></span>'
                        showHtm += color + name + ' ：' + value + '<br>'
                    }
                    return showHtm;
                }
            },
            legend:{
                show:true,
                orient:"horizontal",
                right: 10,
                top: 20,
                textStyle:{
                    color: isDark?"rgba(255,255,255,0.9)":"rgba(34,34,34,1)",
                },
                date:[t('PoolHashrate'),t('NetworkDifficulty')]
            },
            xAxis: {
                type: 'category',
                data: date,
                axisLabel: {
                    formatter: function (params, index) {
                        const date1 = new Date(params * 1000);
                        const h = date1.getHours();
                        const mm = date1.getMinutes();
                        return (h < 10 ? "0" + h : h) + ":" + (mm < 10 ? "0" + mm : mm)
                    },
                    color: isDark?"rgba(255,255,255,0.9)":"rgba(34,34,34,1)",
                    showMinLabel: false,
                    showMaxLabel: false,
                    splitNumber: 10,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['rgba(204,204,204,0.3'],
                        width: 1,
                        type: 'dotted'
                    }
                }
            },
            yAxis: [
                {
                    type: 'value',
                    min: 0,
                    // max: maxHashrate,
                    splitNumber: 5,
                    interval: interval_left,
                    position:'left',
                    axisLabel: {
                        formatter:'{value} GH/s',
                        color: isDark?"rgba(255,255,255,0.9)":"rgba(34,34,34,1)"
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ['rgba(204,204,204,0.3'],
                            width: 1,
                            type: 'dotted'
                        }
                    }
                },
                {
                    type: 'value',
                    min: 0,
                    // max: maxDifficulty,
                    splitNumber: 5,
                    interval: interval_right,
                    position:'right',
                    axisLabel: {
                        show:true,
                        formatter:'{value} G',
                        color: isDark?"rgba(255,255,255,0.9)":"rgba(34,34,34,1)"
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    }
                },
            ],
            series: [
                {
                    name:t('PoolHashrate'),
                    yAxisIndex:0,
                    type: 'line',
                    symbol: 'none',
                    smooth: true,
                    sampling: 'none',
                    itemStyle: {
                        color:  'rgb(55, 58, 168)'
                    },
                    data: hashrates
                },
                {
                    name:t('NetworkDifficulty'),
                    type: 'line',
                    yAxisIndex:1,
                    symbol: 'none',
                    smooth: true,
                    sampling: 'none',
                    itemStyle: {
                        color:  'rgb(255, 158, 68)'
                    },
                    areaStyle: {
                        opacity: 0.2,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgb(255, 158, 68)'
                        }, {
                            offset: 1,
                            color: 'rgb(255, 70, 131)'
                        }])
                    },
                    data: difficultys
                }]
        })
    }
    useEffect(() => {
        initChart();
    }, [chartData, dom,isDark]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={'mainLy'}>
            <div className={'container'} style={{marginTop: '20px', height: "auto"}}>
                {
                    homeData &&
                    <div>
                        <div className={'row'}>
                            <div className={'col-lg-6 col-md-12'}>
                                <div className={'m-1 borderColor'}>
                                    <div className={'padding-2 borderDash_Bottom td_title'}>{t('PoolStatistics')}</div>
                                    <div className={'row'}>
                                        <div className={'col-4 borderDash_Right'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('Miners')}</div>
                                                <div className={'td_text'}>{homeData.minersTotal}</div>
                                            </div>
                                        </div>
                                        <div className={'col-4 borderDash_Right'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('Workers')}</div>
                                                <div className={'td_text'}>{homeData.workerTotal}</div>
                                            </div>
                                        </div>
                                        <div className={'col-4'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('Hashrate')}</div>
                                                <div className={'td_text'}>{hashrate_formatter(homeData.hashrate, 2, 'H/s')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-lg-6 col-md-12'}>
                                <div className={'m-1 borderColor'}>
                                    <div className={'padding-2 borderDash_Bottom td_title'}>{t('BlockStatistics')}</div>
                                    <div className={'row'}>
                                        <div className={'col-4 borderDash_Right'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('CurrentLuck')}</div>
                                                <div className={'td_text'} style={{color: "green"}}>{homeData.luck + ' %'}</div>
                                            </div>
                                        </div>
                                        <div className={'col-4 borderDash_Right'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('LastBlock')}</div>
                                                <div className={'td_text'}>{beautifyTime(homeData.stats.lastBlockFound)}</div>
                                            </div>
                                        </div>
                                        <div className={'col-4'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('BlocksFound')}</div>
                                                <div className={'td_text'}>{homeData.candidatesTotal+homeData.immatureTotal+homeData.maturedTotal}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'row  mt-2'}>
                            <div className={'col-lg-7 col-md-12'}>
                                <div className={'m-1 borderColor'}>
                                    <div className={'padding-2 borderDash_Bottom td_title'}>{t('NetworkStatistics')}</div>
                                    <div className={'row'}>
                                        <div className={'col-4 borderDash_Right'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('NetworkDifficulty')}</div>
                                                <div className={'td_text'}>{hashrate_formatter(homeData.nodes[0].difficulty,2,"")}</div>
                                            </div>
                                        </div>
                                        <div className={'col-4 borderDash_Right'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('NetworkHashrate')}</div>
                                                <div className={'td_text'}>{hashrate_formatter(homeData.nodes[0].networkhashps,2,"H/s")}</div>
                                            </div>
                                        </div>
                                        <div className={'col-4'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('CurrentHeight')}</div>
                                                <div className={'td_text'}>{homeData.nodes[0].height}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {homeData.price &&                             <div className={'col-lg-5 col-md-12'}>
                                <div className={'m-1 borderColor'}>
                                    <div className={'padding-2 borderDash_Bottom td_title'}>{t('CurrentPrice')}</div>
                                    <div className={'row'}>
                                        <div className={'col-6 borderDash_Right'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('PriceUsd')}</div>
                                                <div className={'flex'}>
                                                    <div className={'td_text'}>{homeData.price.usd + '$'}</div>
                                                    <div className={'td_text'}
                                                         style={{color: "green"}}>{parseFloat(homeData.price.usd_change).toFixed(2) + '%'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'col-6'}>
                                            <div className={'padding-2'}>
                                                <div className={'td_text'}>{t('PriceBTC')}</div>
                                                <div className={'flex'}>
                                                    <div className={'td_text'}>{homeData.price.btc + '$'}</div>
                                                    <div className={'td_text'}
                                                         style={{color: "green"}}>{parseFloat(homeData.price.btc_change).toFixed(2) + '%'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                }
                {
                    chartData &&
                    <div className={'mt-2'}>
                        <div style={{height: (chartData.pool&&chartData.pool.length>0)?400:0}} id={'chart1'}/>
                    </div>
                }
                {homeData &&
                    <div className={'borderColor'}>
                        <div className={'rowX'}>
                            <div className={'col3X borderDash_Right borderDash_Bottom padding-2 td_title'} style={{textAlign: 'center'}}>
                                {t('EPOCH')}
                            </div>
                            <div className={'col3X borderDash_Right borderDash_Bottom padding-2 td_title'} style={{textAlign: 'center'}}>
                                {t('DAGSIZE')}
                            </div>
                            <div className={'col3X borderDash_Right borderDash_Bottom padding-2 td_title'} style={{textAlign: 'center'}}>
                                {t('BLOCKREWARD')}
                            </div>
                            <div className={'col3X borderDash_Bottom padding-2 td_title'} style={{textAlign: 'center'}}>
                                {t('MINPAYOUT')}
                            </div>
                        </div>
                        <div className={'rowX'}>
                            <div className={'col3X borderDash_Right padding-2 td_text'} style={{textAlign: 'center'}}>
                                {parseFloat(homeData.nodes[0].height/30000).toFixed(0)}
                            </div>
                            <div className={'col3X borderDash_Right padding-2 td_text'} style={{textAlign: 'center'}}>
                                {/*{getDagSize(homeData.nodes[0].height)}*/}
                                5.047 GB
                            </div>
                            <div className={'col3X borderDash_Right padding-2 td_text'} style={{textAlign: 'center'}}>
                                2.00 ETHW ($22.640)
                            </div>
                            <div className={'col3X padding-2 td_text'} style={{textAlign: 'center', color: 'green'}}>
                                0.1 ETHW
                            </div>
                        </div>
                    </div>
                }
                <div style={{marginTop:'20px'}}>
                    <div className={'td_title'}>{t('How to Connect')}</div>
                    <div className={'borderColor'}>
                        <div className={'rowX'}>
                            <div className={'col3X borderDash_Right padding-2 td_title'} style={{textAlign: 'center'}}>
                                {t('SERVICE')}
                            </div>
                            <div className={'col3X borderDash_Right padding-2 td_title'} style={{textAlign: 'center'}}>
                                {t('DIFFICULTY')}
                            </div>
                            <div className={'col3X borderDash_Right padding-2 td_title'} style={{textAlign: 'center'}}>
                                {t('HOSTPORT')}
                            </div>
                            <div className={'col3X padding-2 td_title'} style={{textAlign: 'center'}}>
                                {t('VARDIFF')}
                            </div>
                        </div>
                        {
                            HOSTPORTMap.map((row, index) => (
                                <div className={'rowX'} key={index}>
                                    <div className={'col3X borderDash_Top borderDash_Right padding-2 td_text'} style={{textAlign: 'center'}}>
                                        {row.SERVICE}
                                    </div>
                                    <div className={'col3X borderDash_Top borderDash_Right padding-2 td_text'} style={{textAlign: 'center'}}>
                                        {row.DIFFICULTY}
                                    </div>
                                    <div className={'col3X borderDash_Top borderDash_Right padding-2 td_text'} style={{textAlign: 'center'}}>
                                        {row.HOSTPORT}
                                    </div>
                                    <div className={'col3X borderDash_Top padding-2 td_text'} style={{textAlign: 'center', color: 'green'}}>
                                        {'√'}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div style={{height:'50px'}}></div>
                </div>
            </div>
            <GlobalSnackbar/>
        </div>
    );
};
export default Home;
