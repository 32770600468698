export const SERVICE = "EthPow";
export const LANGUAGE_TOKENID = "multilanguage_poolweb";
export const DIFFICULTY = "2.18G";
export const HOSTPORT = "eu.ethwmine.com:8008";


export const ETHWSAN = "http://mainnet.ethwscan.com/block/";

export const txUrl = "http://mainnet.ethwscan.com/tx/";
export const addressUrl = "http://mainnet.ethwscan.com/address/";


export const HOSTPORTMap = [
    {
        "SERVICE": "Europe",
        "DIFFICULTY": "2.18G",
        "HOSTPORT": "eu.ethwmine.com:8008",
    },
    {
        "SERVICE": "America",
        "DIFFICULTY": "2.18G",
        "HOSTPORT": "us.ethwmine.com:8008",
    },
    {
        "SERVICE": "Asia",
        "DIFFICULTY": "2.18G",
        "HOSTPORT": "asia.ethwmine.com:8008",
    },
];

export const PPLNS = [
    {
        "name": "Ethereum (ETH)",
        "coin": "eth",
        "host": "https://eth.molepool.com",
        "algorithm": "Ethash",
        "connectedMiners": 13,
        "poolHashrate": 8124426485,
        "blocksFound": 28,
        "poolLuck": 0.051076490842933274,
        "networkHashrate": 804921669791576,
        "networkDifficulty": 11462084577832042,
        "price": 1614.21,
        "priceChange": "-1.32664586",
        "reward": 2,
        "fee": 1.5
    },
    {
        "name": "Ethereum Classic (ETC)",
        "coin": "etc",
        "host": "https://etc.molepool.com",
        "algorithm": "Etchash",
        "connectedMiners": 3,
        "poolHashrate": 1404166716,
        "blocksFound": 57,
        "poolLuck": 0.824890938573727,
        "networkHashrate": 79331508776027,
        "networkDifficulty": 951184790224571,
        "price": 36.36,
        "priceChange": "-2.42068693",
        "reward": 2.56,
        "fee": 1.5
    },
    {
        "name": "Callisto Network (CLO)",
        "coin": "clo",
        "host": "https://clo.molepool.com",
        "algorithm": "Ethash",
        "connectedMiners": 2,
        "poolHashrate": 1533279370,
        "blocksFound": 569,
        "poolLuck": 1.4146830965630244,
        "networkHashrate": 103740878480,
        "networkDifficulty": 1225525577780,
        "price": 0.00282114,
        "priceChange": "1.21602594",
        "reward": 38.88,
        "fee": 1.5
    },
    {
        "name": "Ravencoin (RVN)",
        "coin": "rvn",
        "host": "https://rvn.molepool.com",
        "algorithm": "KawPow",
        "connectedMiners": 1,
        "poolHashrate": 7271500.281602735,
        "blocksFound": 413,
        "poolLuck": 0.4826386192805123,
        "networkHashrate": 9873045694341.004,
        "networkDifficulty": 135443.06665727164,
        "price": 0.063264,
        "priceChange": "-3.02563086",
        "reward": 2500,
        "fee": 1.5
    },
    {
        "name": "Neoxa (NEOX)",
        "coin": "neox",
        "host": "https://neox.molepool.com",
        "algorithm": "KawPow",
        "connectedMiners": 1,
        "poolHashrate": 782693730.7345061,
        "blocksFound": 3,
        "poolLuck": 1.4923625632363628,
        "networkHashrate": 2301273140642.511,
        "networkDifficulty": 32704.123783561517,
        "price": 0.0117374,
        "priceChange": "0.85426816",
        "reward": 4250,
        "fee": 1.5
    },
    {
        "name": "Bitcoin Gold (BTG)",
        "coin": "btg",
        "host": "https://btg.molepool.com",
        "algorithm": "Equihash",
        "connectedMiners": 2,
        "poolHashrate": 615.8521757481112,
        "blocksFound": 65,
        "poolLuck": 1.4002130452155466,
        "networkHashrate": 2158592.151123763,
        "networkDifficulty": 194353.4044233271,
        "price": 23.81,
        "priceChange": "-2.47888739",
        "reward": 6.25,
        "fee": 1.5
    }
]

export const POOLS = [
    {
        "name": "Ethereum (ETH)",
        "coin": "eth",
        "host": "https://eth.molepool.com",
        "algorithm": "Ethash",
        "connectedMiners": 13,
        "poolHashrate": 8333682490,
        "blocksFound": 28,
        "poolLuck": 0.05118090441199077,
        "networkHashrate": 807182240528217,
        "networkDifficulty": 11445844170690124,
        "price": 1614.21,
        "priceChange": "1.32664586",
        "reward": 2,
        "fee": 1.5
    },
    {
        "name": "Ethereum Classic (ETC)",
        "coin": "etc",
        "host": "https://etc.molepool.com",
        "algorithm": "Etchash",
        "connectedMiners": 3,
        "poolHashrate": 1373816888,
        "blocksFound": 57,
        "poolLuck": 0.8257475934906808,
        "networkHashrate": 78881250050469,
        "networkDifficulty": 950256125607995,
        "price": 36.36,
        "priceChange": "-2.42068693",
        "reward": 2.56,
        "fee": 1.5
    },
    {
        "name": "Callisto Network (CLO)",
        "coin": "clo",
        "host": "https://clo.molepool.com",
        "algorithm": "Ethash",
        "connectedMiners": 2,
        "poolHashrate": 1470964916,
        "blocksFound": 569,
        "poolLuck": 1.4673706157041735,
        "networkHashrate": 104469474093,
        "networkDifficulty": 1226123394278,
        "price": 0.00282114,
        "priceChange": "1.21602594",
        "reward": 38.88,
        "fee": 1.5
    },
    {
        "name": "Ravencoin (RVN)",
        "coin": "rvn",
        "host": "https://rvn.molepool.com",
        "algorithm": "KawPow",
        "connectedMiners": 1,
        "poolHashrate": 7599138.062632724,
        "blocksFound": 413,
        "poolLuck": 0.4827708463678334,
        "networkHashrate": 9828764613396.514,
        "networkDifficulty": 135405.96988074368,
        "price": 0.063264,
        "priceChange": "-3.02563086",
        "reward": 2500,
        "fee": 1.5
    },
    {
        "name": "Neoxa (NEOX)",
        "coin": "neox",
        "host": "https://neox.molepool.com",
        "algorithm": "KawPow",
        "connectedMiners": 1,
        "poolHashrate": 759078907.6251755,
        "blocksFound": 3,
        "poolLuck": 1.4923625632363628,
        "networkHashrate": 2301273140642.511,
        "networkDifficulty": 32704.123783561517,
        "price": 0.0117374,
        "priceChange": "0.85426816",
        "reward": 4250,
        "fee": 1.5
    },
    {
        "name": "Bitcoin Gold (BTG)",
        "coin": "btg",
        "host": "https://btg.molepool.com",
        "algorithm": "Equihash",
        "connectedMiners": 2,
        "poolHashrate": 587.9186228482004,
        "blocksFound": 65,
        "poolLuck": 1.4002130452155466,
        "networkHashrate": 2158592.151123763,
        "networkDifficulty": 194353.4044233271,
        "price": 23.81,
        "priceChange": "-2.47888739",
        "reward": 6.25,
        "fee": 1.5
    }
]

// help
export const PayoutLimitMin = "0.1";
export const PayoutLimitMax = "100";
export const FEE = "1";
export const BlockСonfirmation = "60";
export const DefaultMinPayout = "1";