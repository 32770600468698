export const setAppearance = (value) => {
    if (value === "dark") {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
    window.localStorage.setItem("appearance", value);
};
export const getAppearance = () => {
    let appearance = window.localStorage.getItem("appearance");
    if (appearance) {
        return appearance;
    }
    return 'dark';
};
export const getAppearanceBool = () => {
    let appearance = window.localStorage.getItem("appearance");
    if (appearance) {
        return appearance==='dark';
    }
    return true;
};
export const initAppearance = () => {
    const value = getAppearance();
    if (value === "dark") {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
    window.localStorage.setItem("appearance", value);
};
