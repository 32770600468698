import axios from 'axios'
require('dotenv').config();
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*'
const service = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 60000
})
service.interceptors.request.use(config => {
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
            const value = config.params[propName];
            var part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof (value) !== "undefined") {
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        url += subPart + encodeURIComponent(value[key]) + "&";
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config
}, error => {
    console.log(error)
})

service.interceptors.response.use(res => {
        const msg = "Network Error"
        if (res.status === 200) {
            return Promise.resolve(res.data)
        } else {
            return Promise.reject(new Error(msg))
        }
    },
    error => {
        return Promise.reject(error)
    }
)
export default service
