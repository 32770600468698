import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

BigNumber.config({ROUNDING_MODE: BigNumber.ROUND_DOWN});
BigNumber.config({EXPONENTIAL_AT: 100});

export const getDayjsYMD = (timestamp) => {
    return dayjs(timestamp * 1000).format("YYYY-MM-DD")
}
export const getDayjsMDHMS = (timestamp) => {
    return dayjs(timestamp * 1000).format("MM-DD HH:mm:ss")
}
export const getDayjsYMDHMS = (timestamp) => {
    return dayjs(timestamp * 1000).format("YY-MM-DD HH:mm:ss")
}
export const getDayjsHMS = (timestamp) => {
    return dayjs(timestamp * 1000).format("A hh:mm:ss")
}
export const getDayjsYMDHM = (timestamp) => {
    return dayjs(timestamp * 1000).format("YYYY/MM/DD HH:mm")
}
export const diffTime = (timestamp) => {
    var mistiming = dayjs().unix()-timestamp;
    return mistiming
}
export const beautifyTime = (timestamp) => {
    var mistiming = dayjs().unix()-timestamp;
    var postfix = mistiming>0 ? ' seconds ago' : ' seconds after'
    mistiming = Math.abs(mistiming)
    var inm = Math.floor(mistiming)
    return inm + postfix
}


export const format = (value) => {
    const fl = new BigNumber(value).div("1000000000").toFixed();
    return setNumFormat(parseFloat(toBigNumber(fl).toFixed(6)) + "");
};
export const formatTow = (value) => {
    const fl = new BigNumber(value).div("1000000000").toFixed();
    return setNumFormat(parseFloat(toBigNumber(fl).toFixed(2)) + "");
};
export const formatEightTow = (value) => {
    const fl = new BigNumber(value).div("100000000").toFixed();
    return setNumFormat(parseFloat(toBigNumber(fl).toFixed(2)) + "");
};
export const formatReward = (value) => {
    const fl = new BigNumber(value).div("1000000000000000000").toFixed();
    return setNumFormat(parseFloat(toBigNumber(fl).toFixed(6)) + "");
};
export const toBigNumber = (value) => {
    const valueString = typeof value === "string" ? value : String(value);
    return new BigNumber(valueString);
};
export const setNumFormat = (value) => {
    if (!value) return "0";
    const intPart = Number(value) - Number(value) % 1;
    const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    const value2Array = value.toString().split(".");
    if (value2Array.length === 2) {
        const floatPart = value2Array[1].toString();
        return intPartFormat + "." + floatPart;
    } else {
        return intPartFormat;
    }
};

export const dateDiff = (date) => {
    dayjs.extend(relativeTime)
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: '%d seconds',
            m: "%d minute",
            mm: "%d minutes",
            h: "%d hour",
            hh: "%d hours",
            d: "%d day",
            dd: "%d days",
            M: "%d month",
            MM: "%d months",
            y: "%d year",
            yy: "%d years"
        }
    })
    let ydate = dayjs(date * 1000).format("YYYY-MM-DD HH:mm:ss")
    let diff = dayjs(ydate).fromNow()
    return diff;
}

// private function
export const hashrate_formatter = (value, decimal, unit, isSuffix = true) => {
    if (value === 0) {
        return '0 ' + unit;
    } else {
        let i;
        const si = [
            {value: 1, symbol: ""},
            {value: 1e3, symbol: "K"},
            {value: 1e6, symbol: "M"},
            {value: 1e9, symbol: "G"},
            {value: 1e12, symbol: "T"},
            {value: 1e15, symbol: "P"},
            {value: 1e18, symbol: "E"},
            {value: 1e21, symbol: "Z"},
            {value: 1e24, symbol: "Y"},
        ];
        for (i = si.length - 1; i > 0; i--) {
            if (value >= si[i].value) {
                break;
            }
        }
        if (isSuffix) {
            return (value / si[i].value).toFixed(decimal).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + ' ' + si[i].symbol + unit;
        } else {
            return (value / si[i].value).toFixed(decimal).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + ' ' + unit;
        }
    }
}

const epochLength= 30000;
const maxEpoch= 2048;
export const getDagSize = (block) => {
    const epoch = parseFloat(block/30000);
    const size = Math.pow(2,24) + Math.pow(2,17) *epoch - 64;
    return hashrate_formatter(size,2,"B");
};


