import "./index.css"
import GlobalSnackbar from "../../view/globalSnackbar/GlobalSnackbar";
import {useTranslation} from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer, {tableContainerClasses} from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {styled} from "@mui/material/styles";
import {PPLNS,POOLS} from "../../../config/config";
import React from "react";
import MenuBookIcon from '@mui/icons-material/MenuBook'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import {hashrate_formatter} from "../../../utils/common";


const Blocks = () => {
    const {t} = useTranslation();


    return (
        <div className={'mainLy'}>
            <div className={'container'} style={{marginTop: '20px'}}>
                        <div className={'topTitle'}>
                            <h2 className="text-capitalize">{t('Effective PPLNS and SOLO mining')}</h2>
                            <h5 className="mb-6">{t('Professional pool with maximum performance')}</h5>
                            <h6 className="text-uppercase">
                                <div className={'top_sm_title'}>{t('Stable')}</div>
                                <div className={'top_sm_title spot'}> ● {t('Reliable')}</div>
                                <div className={'top_sm_title spot'}> ● {t('Transparent')}</div>
                            </h6>
                        </div>

                        <h5 className={'pplnsStyle'}>{t('Mining PPLNS')}</h5>

                        <StyledTableContainer className={'borderColor mb-2'} >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">{t('POOL')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('MINERS')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('POOL HASHRATE')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('EFFORT')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('NETWORK HASHRATE')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('DIFFICULTY')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('PRICE')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('REWARD')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {PPLNS && PPLNS.map((row, index) => (
                                            <TableRow key={index}>
                                                <StyledTableCell>
                                                    <a
                                                        className={'text-a'}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={row.host}
                                                    >{row.name}</a>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{row.connectedMiners}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {hashrate_formatter(row.poolHashrate, 2, 'H/s')}
                                                </StyledTableCell>
                                                {/*<StyledTableCell align="left">{row.server}</StyledTableCell>*/}
                                                <StyledTableCellGreen align="left">{(row.poolLuck * 100).toFixed(2)+"%"}</StyledTableCellGreen>
                                                <StyledTableCell align="left">{hashrate_formatter(row.networkHashrate, 2, 'H/s')}</StyledTableCell>
                                                <StyledTableCellGreen
                                                    align="left">
                                                    {hashrate_formatter(row.networkDifficulty, 2, '')}
                                                </StyledTableCellGreen>
                                                <StyledTableCell
                                                    align="left">{getAmplitude(row.priceChange,row.price)}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left">{getCalculateAmount(row.reward,row.fee,row.coin)}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </StyledTableContainer>

                        <h5 className={'pplnsStyle'}>{t('Mining SOLO')}</h5>

                        <StyledTableContainer className={'borderColor mb-2'}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">{t('POOL')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('POOL HASHRATE')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('EFFORT')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('BLOCKS')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('NETWORK HASHRATE')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('DIFFICULTY')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('PRICE')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('REWARD')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {POOLS && POOLS.map((row, index) => (
                                        <TableRow key={index}>
                                            <StyledTableCell>
                                                <a
                                                    className={'text-a'}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={row.host}
                                                >{row.name}</a>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{hashrate_formatter(row.poolHashrate, 2, 'H/s')}</StyledTableCell>
                                            <StyledTableCellGreen align="left">
                                                {(row.poolLuck * 100).toFixed(2)+"%"}
                                            </StyledTableCellGreen>
                                            {/*<StyledTableCell align="left">{row.server}</StyledTableCell>*/}
                                            <StyledTableCell align="left">{row.blocksFound}</StyledTableCell>
                                            <StyledTableCell align="left">{hashrate_formatter(row.networkHashrate, 2, 'H/s')}</StyledTableCell>
                                            <StyledTableCellGreen
                                                align="left">{hashrate_formatter(row.networkDifficulty, 2, '')}</StyledTableCellGreen>
                                            <StyledTableCell
                                                align="left">{getAmplitude(row.priceChange,row.price)}</StyledTableCell>
                                            <StyledTableCell
                                                align="left">{getCalculateAmount(row.reward,row.fee,row.coin)}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </StyledTableContainer>

                        <div className={'tableMin1'}>
                            <div className={'row'}>
                                <div xs={12} className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12'} style={{padding:'12px'}}>
                                    <div className={' borderColor'}>
                                        <div className={'icon'}><FreeBreakfastIcon></FreeBreakfastIcon></div>
                                        <div className={'content'}>
                                            <div className={'iconTitle'}>{t('Anonymous Mining')}</div>
                                            <div className={'iconTitle'} style={{color: 'var(--divide1-color)'}}>{t('No Registration Required To Start.')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div xs={12} className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12'} style={{padding:'12px'}}>
                                    <div className={' borderColor'} >
                                        <div className={'icon'}><MenuBookIcon></MenuBookIcon></div>
                                        <div className={'content'}>
                                            <div className={'iconTitle'}>{t('Multi-threaded Stratum')}</div>
                                            <div className={'iconTitle'} style={{color: 'var(--divide1-color)'}}>{t('Ultra-low latency, asynchronous I/O.')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div xs={12} className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12'} style={{padding:'12px'}}>
                                    <div className={'borderColor'}>
                                        <div className={'icon'}><DoneAllIcon></DoneAllIcon></div>
                                        <div className={'content'}>
                                            <div className={'iconTitle'}>{t('PoW Validation')}</div>
                                            <div className={'iconTitle'} style={{color: 'var(--divide1-color)'}}>{t('Native for maximum performance.')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
            <GlobalSnackbar/>
        </div>
    );
};

export const getCalculateAmount = (reward,fee,coin) => {
    var s = (reward * fee).toFixed(2);
    const r = reward+" "+coin
    return <div ><span style={{textTransform: "uppercase"}}>{r}</span><span style={{color: '#4caf50'}}>{" ($"+s+")"}</span></div>
}


export const getAmplitude = (priceChange,price) => {
    const s = (priceChange * 100).toFixed(2);

    if (priceChange >= 0){
        return <div><span>{"$" +price.toFixed(3)+" "}</span><span style={{color: '#4caf50'}}>{"+"+s+"%↑"}</span></div>
    }else {
        return <div><span>{"$" +price.toFixed(3)+" "}</span><span style={{color: '#f44336'}}>{s+"%↓"}</span></div>
    }
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'var(--text-color)',
        fontWeight: 'bold',
        fontSize: 14,
        borderColor: 'var(--divide1-color)',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'var(--text-color)',
        borderColor: '#00000000',

    },
}));


const StyledTableCellGreen = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'var(--table-color-green)',
        borderColor: '#00000000',

    },
}));


const StyledTableContainer = styled(TableContainer)(({theme}) => ({
    [`&.${tableContainerClasses.root}`]: {
        backgroundColor: 'transparent',
        borderRadius: 0,
    },
}));

export default Blocks;
