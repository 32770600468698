import React, {useEffect, useState} from "react";
import "./Navigation.css";
import logoImg from "../../assets/icons/logo.png";
import {NavLink} from "react-router-dom";
import LanguageBtn from "../view/LanguageBtn";
import {FormControlLabel} from "@mui/material";
import {useTranslation} from "react-i18next";
import HomeIcon from "@mui/icons-material/Home";
import GridViewIcon from "@mui/icons-material/GridView";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PaidIcon from "@mui/icons-material/Paid";
import HelpIcon from "@mui/icons-material/Help";
import {ColorMenu, MaterialUISwitch} from "../../utils/view";
import {getAppearanceBool, initAppearance, setAppearance} from "../../config/useAppearance";
import {reactLocalStorage} from "reactjs-localstorage";
import {LANGUAGE_TOKENID} from "../../config/config";

const Navigation = ({changeDarkMode}) => {
    let userProfile = reactLocalStorage.getObject(LANGUAGE_TOKENID) || {};
    const {t, i18n} = useTranslation();
    const language = userProfile['lng'] || i18n.language;
    const [mode, setMode] = useState(getAppearanceBool())
    const [anchorEl, setAnchorEl] = React.useState(null);
    useEffect(() => {
        i18n.changeLanguage(language, null).then(() => {
        })
    }, [i18n]);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const changeMode = (e) => {
        setMode(e.target.checked)
        if (e.target.checked) {
            setAppearance('dark')
            initAppearance()
        } else {
            setAppearance('light')
            initAppearance()
        }
        changeDarkMode()
    }
    return (
        <div className="headerc">
            <div className="logo">
                <img src={logoImg} alt="logo" width='32'/>
                <div style={{
                    marginLeft: '10px', whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>EthwMine Pool
                </div>
            </div>

            <ul className={'nav'}>
                <NavLink className="navigation" to="/"
                         style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                    <HomeIcon></HomeIcon>
                    <div style={{marginLeft: '8px'}}>{t('Home')}</div>
                </NavLink>
                <NavLink className="navigation" to="/blocks"
                         style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                    <GridViewIcon></GridViewIcon>
                    <div style={{marginLeft: '8px'}}>{t('Blocks')}</div>
                </NavLink>
                <NavLink className="navigation" to="/miners"
                         style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                    <PeopleAltIcon></PeopleAltIcon>
                    <div style={{marginLeft: '8px'}}>{t('Miners')}</div>
                </NavLink>
                <NavLink className="navigation" to="/payouts"
                         style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                    <PaidIcon></PaidIcon>
                    <div style={{marginLeft: '8px'}}>{t('Payouts')}</div>
                </NavLink>
                <NavLink className="navigation" to="/help"
                         style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                    <HelpIcon></HelpIcon>
                    <div style={{marginLeft: '8px'}}>{t('Help')}</div>
                </NavLink>
                {/*<NavLink className="navigation m-4" to="/pools" onClick={handleClose}*/}
                {/*         style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>*/}
                {/*    <FormatAlignJustifyIcon></FormatAlignJustifyIcon>*/}
                {/*    <div style={{marginLeft: '8px'}}>{t('Pools')}</div>*/}
                {/*</NavLink>*/}
            </ul>
            <div className={'nav-right'}>
                <LanguageBtn/>
                <a
                    className="icon-link-item"
                    rel="noreferrer"
                    target="_blank" href="https://t.me/ethwmine_official">
                    <img
                        className="link-img"
                        src="/telegram.svg" alt="telegram"/>
                </a>
                <FormControlLabel
                    labelPlacement="start"
                    control={<MaterialUISwitch sx={{m: 0.5}} checked={mode} onChange={changeMode}/>}
                    label={""}
                />
            </div>
            <div className={'mobile-nav'} id={'mobile-nav'}>
                <div className={'menuBtn'} onClick={handleClick}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
                <ColorMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <NavLink className="navigation m-4" to="/" onClick={handleClose}
                             style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                        <HomeIcon></HomeIcon>
                        <div style={{marginLeft: '8px'}}>{t('Home')}</div>
                    </NavLink>
                    <NavLink className="navigation m-4" to="/blocks" onClick={handleClose}
                             style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                        <GridViewIcon></GridViewIcon>
                        <div style={{marginLeft: '8px'}}>{t('Blocks')}</div>
                    </NavLink>
                    <NavLink className="navigation m-4" to="/miners" onClick={handleClose}
                             style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                        <PeopleAltIcon></PeopleAltIcon>
                        <div style={{marginLeft: '8px'}}>{t('Miners')}</div>
                    </NavLink>
                    <NavLink className="navigation m-4" to="/payouts" onClick={handleClose}
                             style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                        <PaidIcon></PaidIcon>
                        <div style={{marginLeft: '8px'}}>{t('Payouts')}</div>
                    </NavLink>
                    <NavLink className="navigation m-4" to="/help" onClick={handleClose}
                             style={({isActive}) => ({color: isActive ? "var(--main-color)" : "var(--title-color)"})}>
                        <HelpIcon></HelpIcon>
                        <div style={{marginLeft: '8px'}}>{t('Help')}</div>
                    </NavLink>
                </ColorMenu>
            </div>
        </div>
    );
};

export default Navigation;
