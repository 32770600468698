import React from "react";
import "../../page/MainBody/index.css";
import {ColorPagination} from "../../../utils/view";

const PageView = ({pageNum,pages,setPage}) => {
    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <div className={'paginationView'}>
            <ColorPagination count={parseInt(pages)} page={parseInt(pageNum)} onChange={handleChange} />
        </div>
    );
};

export default PageView;
