import {styled} from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";
import {Card, Dialog, Drawer, InputBase, Slider, Switch, Tab, Tabs, ToggleButton,Popover} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React from "react";
import Menu from "@mui/material/Menu";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
export const ColorPagination = styled(Pagination)(({theme}) => ({
    ul: {
        "& .MuiPaginationItem-root": {
            color: "var(--title-color)"
        },
        "& .Mui-selected": {
            backgroundColor: "var(--main-color-50)"
        },
        "& .Mui-selected:hover": {
            backgroundColor: "var(--main-color)"
        }
    }
}));
export const ColorButton = styled(Button)(({theme}) => ({
    color: 'white',
    backgroundColor: 'var(--main-color-bt)',
    '&:hover': {
        backgroundColor: 'var(--main-color-press )',
    },
}));

export const ColorTextButton = styled(Button)(({theme}) => ({
    color: 'var(--main-color-bt)',
    '&:hover': {
        color: 'var(--main-color-press )',
    },
}));
export const ColorOutLineButton = styled(Button)(({theme}) => ({
    color: 'var(--main-color-bt)',
    borderColor: 'var(--main-color-bt)',
    '&:hover': {
        borderColor: 'var(--main-color-press )',
    },
}));
export const ColorLoadingButton = styled(LoadingButton)(({theme}) => ({
    color: 'white',
    backgroundColor: 'var(--main-color-bt)',
    '&:hover': {
        backgroundColor: 'var(--main-color-press)',
    },
}));
export const ColorButton160 = styled(Button)(({theme}) => ({
    color: 'white',
    minWidth: '160px',
    backgroundColor: 'var(--main-color-bt)',
    '&:hover': {
        backgroundColor: 'var(--main-color-press )',
    },
}));
export const ColorLoadingButton150 = styled(LoadingButton)(({theme}) => ({
    color: 'white',
    minWidth: '150px',
    marginRight:'10px',
    backgroundColor: 'var(--main-color-bt)',
    '&:hover': {
        backgroundColor: 'var(--main-color-press)',
    },
}));
export const ColorLoadingButton50 = styled(LoadingButton)(({theme}) => ({
    color: 'white',
    width: '50px',
    fontSize: '11px',
    backgroundColor: 'var(--main-color-bt)',
    '&:hover': {
        backgroundColor: 'var(--main-color-press)',
    },
}));
export const ColorButtonTranBg = styled(Button)(() => ({
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'var(--main-color-press)',
    },
}));
export const ColorButtonBg = styled(Button)(() => ({
    backgroundColor: 'var(--divide-color)',
    '&:hover': {
        backgroundColor: 'var(--main-color-press)',
    },
}));
export const AccountDrawer = styled(Drawer)(({theme}) => ({
    '& 	.MuiDrawer-paper': {
        backgroundColor: 'var(--card-bg-color)',
    },
}));
export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

export const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: 'var(--main-text-color)',
    },
});
export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '17px',
        marginRight: '10px',
        color: 'var(--title-color)',
        '&.Mui-selected': {
            color: 'var(--main-text-color)',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);


export const ColorInputBase = styled(InputBase)(() => ({
    flexGrow: 1,
    color: 'var(--title-color)',
    background: 'transparent',
    fontSize: '1rem',
    '& 	.MuiInputBase-input': {
        width: '100%',
        padding: '3.5px',
        textAlign: 'left',
    },
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: "30px",
    },
    '& .MuiInputBase-input': {
        borderRadius: 5,
        position: 'relative',
        color: 'var(--title-color)',
        backgroundColor: 'transparent',
        border: '1px solid var(--main-color-bt)',
        fontSize: 16,
        padding: '10px 10px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `var(--main-color-50) 0 0 0 0.2rem`,
            borderColor: "var(--main-color-bt)",
        },
    },
}));


export const ColorMenu = styled(Menu)(() => ({
    '& .MuiPaper-root': {
        backgroundColor: 'var(--card-bg-color)',
        color: 'var(--text-color)'
    },
    '& .MuiMenuItem-root': {

        '&:hover': {
            backgroundColor: 'var(--main-color-press)',
        },
        '&:active': {
            backgroundColor: 'var(--main-color-press)',
        },
    },
}));
export const ColorPopover = styled(Popover)(() => ({
    '& .MuiPopover-paper': {
        backgroundColor: 'var(--card-bg-color)',
        color: 'var(--text-color)'
    }
}));
export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    backgroundColor: 'transparent',
}));
export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{fontSize: '1rem', color: 'var(--text-color)'}}/>}
        {...props}
    />
))(() => ({
    borderColor: 'transparent',
    borderRadius: '5px',
    height: '50px',
    // backgroundColor:'transparent',
    backgroundColor: 'var(--card-bg-color)',
    color: 'var(--text-color)'
}));
export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: '20px',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: 'var(--background-color)',
    color: 'var(--text-color)'
}));

export const CustomerSlider = styled(Slider)({
    color: 'var(--main-color-bt)',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: 'var(--main-color-bt)',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': {display: 'none'},
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

export const ColorToggleButton = styled(ToggleButton)(() => ({
    minWidth:'120px',
    padding:'10px 20px',
    fontWeight: 600,
    color: 'var(--title-color)',
    '&.MuiToggleButton-root': {
        border: '1px solid var(--divide-color)',
    },
    '&.Mui-selected': {
        backgroundColor: 'var(--main-color-bt)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'var(--main-color-bt)',
        },
    },
    '&:hover': {
        backgroundColor: 'var(--main-color-bt)',
    },
}));
export const ColorCard = styled(Card)(({theme}) => ({
    '&.MuiCard-root': {
        color: 'var(--title-color)',
        backgroundColor: 'var(--background-color)',
        boxShadow: '0px 2px 1px -1px transparent',
        border: '1px solid var(--divide-color)',
        '&:hover': {
            backgroundColor: 'var(--card-bg-color)',
        },
    },
}));



export const ColorDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        backgroundColor: 'var(--dialog-bg)',
        color: 'var(--text-color)'
    },
}));
