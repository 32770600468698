import React, {useEffect, useState} from 'react'
import "./index.css"
import GlobalSnackbar from "../../view/globalSnackbar/GlobalSnackbar";
import service from "../../../config/request";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {hashrate_formatter} from "../../../utils/common";
import {dateDiff} from "../../../utils/common";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Badge
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {tableCellClasses} from "@mui/material/TableCell";
import PageView from "../../view/PageView/PageView";
import TableContainer, {tableContainerClasses} from "@mui/material/TableContainer";

const Miners = () => {
    const {t} = useTranslation();
    const [minersData, setMinersData] = useState(null);
    const [statsData, setStatsData] = useState(null);
    const {search} = useLocation();
    const [minerList, setMinerList] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const rowsPerPage = 10;
    const order = 'desc';
    const orderBy = 'lastBeat';


    useEffect(() => {
        function getData() {
            service({
                url: '/miners',
                method: 'get',
            }).then(response => {
                setMinersData(response)
                const miners = Object.entries(response.miners);
                setPage(1)
                const num = miners.length;
                const remainder = num % rowsPerPage;
                let pages = num / rowsPerPage;
                if (remainder > 0) {
                    pages = pages + 1;
                }
                setPages(pages)
                if (num > rowsPerPage) {
                    setMinerList(miners.slice(0, rowsPerPage))
                } else {
                    setMinerList(miners)
                }
            }).catch(error => {
                GlobalSnackbar.showMessage(error.message, 'error')
            })
        }

        function getStats() {
            service({
                url: '/stats',
                method: 'get',
            }).then(response => {
                setStatsData(response)
            }).catch(error => {
                GlobalSnackbar.showMessage(error.message, 'error')
            })
        }

        getData();
        getStats();
    }, [search]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleChangePage = (newPage
    ) => {
        setPage(newPage)
        const miners = Object.entries(minersData.miners);
        setMinerList(miners.slice((newPage - 1) * rowsPerPage, newPage * rowsPerPage))
    };


    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => {
            el[1]['hash'] = el[0]
            return [el[1], index]
        });
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    return (
        <div className={'mainLy'}>
            <div className={'container'} style={{marginTop: '20px'}}>
                {
                    minersData &&
                    <div>
                        {
                            statsData &&
                            <div className={'borderColor'}>
                                <div className={'p-2 borderDash_Bottom td_title'}>{t('PoolStatistics')}</div>
                                <div className={'row'}>
                                    <div className={'col-4 borderDash_Right'}>
                                        <div className={'p-2'}>
                                            <div className={'td_text'}>{t('Miners')}</div>
                                            <div className={'td_text'}>{statsData.minersTotal}</div>
                                        </div>
                                    </div>
                                    <div className={'col-4 borderDash_Right'}>
                                        <div className={'p-2'}>
                                            <div className={'td_text'}>{t('Workers')}</div>
                                            <div className={'td_text'}>{statsData.stats.workerTotal}</div>
                                        </div>
                                    </div>
                                    <div className={'col-4'}>
                                        <div className={'p-2'}>
                                            <div className={'td_text'}>{t('Hashrate')}</div>
                                            <div
                                                className={'td_text'}>{hashrate_formatter(statsData.hashrate, 2, 'H/s')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <StyledTableContainer className={'borderColor mt-2'}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">{t('Miner')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('Hashrate')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('Last Share')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        stableSort(minerList, getComparator(order, orderBy))
                                            .map((row, index) => (
                                                <TableRow key={index}>
                                                    <StyledTableCell className={'auto_color'} align="left">
                                                        <Badge className={'mb-1'}
                                                               color="success" variant="dot"
                                                               invisible={row.offline}/>
                                                        <Badge className={'mb-1'}
                                                               color="error" variant="dot"
                                                               invisible={!row.offline}/>
                                                        <a className={'ms-3 auto_color'}
                                                           style={{fontFamily: 'Consolas,Monaco,monospace'}}
                                                           rel={'noopener noreferrer'}
                                                           href={'/accounts/' + row.hash}>{row.hash}</a>
                                                    </StyledTableCell>
                                                    <StyledTableCell className={'auto_color'}
                                                                     align="left">{hashrate_formatter(row.hr, 2, 'H/s')}</StyledTableCell>
                                                    <StyledTableCell className={'auto_color'}
                                                                     align="left">{dateDiff(row.lastBeat)}</StyledTableCell>
                                                </TableRow>
                                            ))
                                    }
                                </TableBody>
                            </Table>
                            <div className={'v-data-footer'}>
                                <PageView pageNum={page} pages={pages} setPage={handleChangePage}/>
                            </div>
                        </StyledTableContainer>
                    </div>
                }
            </div>
            <GlobalSnackbar/>
        </div>
    );
};
const StyledTableContainer = styled(TableContainer)(({theme}) => ({
    [`&.${tableContainerClasses.root}`]: {
        backgroundColor: 'transparent',
        borderRadius: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'var(--text-color)',
        fontWeight: 'bold',
        borderColor: 'var(--divide1-color)'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'var(--text-color)',
        borderColor: 'var(--divide1-color)'
    },
}));

export default Miners;
