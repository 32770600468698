import React, {useEffect} from 'react'
import "./index.css"
import GlobalSnackbar from "../../view/globalSnackbar/GlobalSnackbar";
import service from "../../../config/request";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    format,
    formatEightTow,
    formatTow,
    getDayjsYMDHM,
    setNumFormat
} from "../../../utils/common";
import PageView from "../../view/PageView/PageView";
import BigNumber from "bignumber.js";
import {styled} from "@mui/material/styles";
import {txUrl,addressUrl} from "../../../config/config";


const Payouts = () => {
    const {t} = useTranslation();
    const [paymentsData, setPaymentsData] = React.useState(null);
    const [statsData, setStatsData] = React.useState(null);
    const [payments, setPayments] = React.useState([]);
    const {search} = useLocation();
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const rowsPerPage = 10;
    // const [countPerPage,setCountPerPage] = React.useState(10);

    useEffect(() => {
        function getData() {
            service({
                url: '/payments',
                method: 'get',
            }).then(response => {
                setPaymentsData(response)
                // setCountPerPage(response.payments.length)
                setPage(1)
                if (response.payments){
                    var num = response.payments?response.payments.length:0;
                    var remainder = num % rowsPerPage;
                    var pages = num / rowsPerPage;
                    if (remainder > 0) {
                        pages = pages + 1;
                    }
                    setPages(pages)
                    if (num > rowsPerPage) {
                        setPayments(response.payments.slice(0, rowsPerPage))
                    } else {
                        setPayments(response.payments)
                    }
                }
            }).catch(error => {
                GlobalSnackbar.showMessage(error.message, 'error')
            })
        }

        function getStats() {
            service({
                url: '/stats',
                measure: 'get',
            }).then(response => {
                setStatsData(response);
            }).catch(error => {
                GlobalSnackbar.showMessage(error.message, 'error')
            })
        }

        getStats();
        getData();
    }, [search]);// eslint-disable-line react-hooks/exhaustive-deps
    const handleChangePage = (newPage
    ) => {
        setPage(newPage)
        setPayments(paymentsData.payments.slice((newPage - 1) * rowsPerPage, newPage * rowsPerPage))
    };

    const setUSd = (value) => {
        if (!value) return "0";
        if (!statsData) return "0";
        if (!statsData.price) return "0"
        if (!statsData.price.usd) return "0"
        let bigNumber = new BigNumber(statsData.price.usd);
        return new BigNumber(value).times(bigNumber).toNumber()
    };

    return (
        <div className={'mainLy'}>
            <div className={'container'} style={{marginTop: '20px'}}>
                {
                    paymentsData &&
                    <div className={'payments'}>
                        <div className={'row m-1 borderColor'}>
                            <div className={'padding-2 borderDash_Bottom td_title'}>{t('PayoutStatistics')}</div>
                            <div className={'row'}>
                                <div className={'col-4 borderDash_Right'}>
                                    <div className={'padding-2'}>
                                        <div className={'td_text'}>{t('TotalPayouts')}</div>
                                        <div className={'td_text'}>
                                            <span>{setNumFormat(paymentsData.paymentsTotal)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-4 borderDash_Right'}>
                                    <div className={'padding-2'}>
                                        <div className={'td_text'}>{t('Payouts')}</div>
                                        <div className={'td_text'}>
                                            {formatTow(paymentsData.paymentsSum)} ETHW
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-4'}>
                                    <div className={'padding-2'}>
                                        <div className={'td_text'}>{t('Payouts')}</div>
                                        <div className={'td_text'}>
                                            <span> $ {formatEightTow(setUSd(paymentsData.paymentsSum))}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'v-data-table border rounded-0 card'}>
                            <div className={'v-data-table__wrapper'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>{t('Time')}</StyledTableCell>
                                            <StyledTableCell>{t('Amount')}</StyledTableCell>
                                            <StyledTableCell>{t('Miner')}</StyledTableCell>
                                            <StyledTableCell>{t('TxID')}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {payments.map((item, row) => (
                                            <TableRow key={row}>
                                                <TableCell className={'TableCell__text'}
                                                           align="left">{getDayjsYMDHM(item.timestamp)}</TableCell>
                                                <TableCell className={'TableCell__text'}
                                                           align="left">{format(item.amount)}</TableCell>
                                                <TableCell className={'TableCell__text'} align="left">
                                                    <a className={' text-monospace'}
                                                       href={addressUrl + item.address}
                                                       style={{fontFamily: 'Consolas,Monaco,monospace'}}
                                                       target={'_blank'}
                                                       rel={'noopener noreferrer'}>
                                                        {item.address}</a>
                                                </TableCell>
                                                <TableCell className={'TableCell__text'} align="left">
                                                    <a className={' text-monospace'}
                                                       style={{fontFamily: 'Consolas,Monaco,monospace'}}
                                                       href={txUrl + item.tx}
                                                       target={'_blank'}
                                                       rel={'noopener noreferrer'}>
                                                        {item.tx.slice(0, 16)}...{item.tx.slice(item.tx.length - 16, item.tx.length)}</a>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                                <div className={'v-data-footer'}>
                                    <PageView
                                        pageNum={page}
                                        pages={pages}
                                        setPage={handleChangePage}
                                    ></PageView>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {!paymentsData && <div>
                    {t('NoDataYet')}
                </div>}
            </div>
            <GlobalSnackbar/>
        </div>
    );
};
const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'var(--text-color)',
        fontWeight: 'bold',
        borderColor: 'var(--divide1-color)'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: 'var(--text-color)',
        borderColor: 'var(--divide1-color)'
    },
}));
export default Payouts;
