import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import HomeHook from "./components/page/Home";
import Blocks from "./components/page/Blocks";
import Miners from "./components/page/Miners";
import Account from "./components/page/Account";
import Payouts from "./components/page/Payouts";
import Help from "./components/page/Help";
import Pools from "./components/page/Pools";
import {getAppearanceBool, initAppearance} from "./config/useAppearance";


export default class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            isDark: getAppearanceBool()
        }
    }

    async componentDidMount() {
        initAppearance()
    }

    changeDarkMode() {
        const dark = getAppearanceBool();
        this.setState({isDark: dark})
    }

    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <div className={"header"}>
                        <Navigation changeDarkMode={this.changeDarkMode.bind(this)}/>
                    </div>
                    <Routes>
                        <Route path="/" element={<HomeHook pathType={'home'} isDark={this.state.isDark}/>}/>
                        <Route path="/blocks" element={<Blocks pathType={'home'}/>}/>
                        <Route path="/miners" element={<Miners pathType={'home'}/>}/>
                        <Route path="/accounts/*" element={<Account pathType={'home'} isDark={this.state.isDark}/>}/>
                        <Route path="/payouts" element={<Payouts pathType={'home'}/>}/>
                        <Route path="/help" element={<Help pathType={'home'}/>}/>
                        <Route path="/pools" element={<Pools pathType={'home'}/>}/>
                    </Routes>

                    <div className={'power-by'}>
                        <div>Pool Account: 0xFb201126E7a80419c8C11C466358593CdA76981c</div>
                        <div>
                            {'PPLNS Mining v0.0.1 © '}
                            <a href="https://www.ethwmine.com/"
                               target='_blank' rel="noopener noreferrer">ethwmine.com</a>
                            {' 2022 - 2022'}
                        </div>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}



