import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import ReactDOM from "react-dom";
import React from "react";
import App from "./App";
import {reactLocalStorage} from "reactjs-localstorage";
import {LANGUAGE_TOKENID} from "./config/config";

let userProfile = reactLocalStorage.getObject(LANGUAGE_TOKENID) || {};
const language = userProfile['lng'] || i18n.language;
i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: language,
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    }).then(() => ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root'),
    )
);

export default i18n;
