import React from 'react'
import "./index.css"
import GlobalSnackbar from "../../view/globalSnackbar/GlobalSnackbar";
import {useTranslation} from "react-i18next";
import {HOSTPORTMap} from "../../../config/config";
import {PayoutLimitMax,PayoutLimitMin,FEE,BlockСonfirmation,DefaultMinPayout} from "../../../config/config";


const Help = () => {
    const {t} = useTranslation();

    const map = HOSTPORTMap

    return (
        <div className={'mainLy'}>
            <div className={'container'} style={{marginTop: '20px'}}>
                {t('1.Pool information')}
                <div className={'row'}>
                    <table className="table table1 table-bordered">
                        <thead>
                        <tr>
                            <td >{t('Payout Scheme')}</td>
                            <td > {t('PPLNS (Pay Per Last N Shares)')}</td>
                        </tr>
                        <tr>
                            <td > {t('Fee')}</td>
                            <td > {FEE}</td>
                        </tr>
                        <tr>
                            <td > {t('Payout Interval')}</td>
                            <td > {t('10 Minutes')}</td>
                        </tr>
                        <tr>
                            <td > {t('Payout Limit')}</td>
                            <td >
                                {t('Min: ')}
                                {PayoutLimitMin+"ETHW"}
                                {" , "}
                                {t('Max: ')}
                                {PayoutLimitMax+"ETHW"}
                            </td>
                        </tr>
                        <tr>
                            <td > {t('Default Min Payout')}</td>
                            <td > {DefaultMinPayout+" ETHW"}</td>
                        </tr>
                        <tr>
                            <td > {t('Block Сonfirmation')}</td>
                            <td >{BlockСonfirmation}</td>
                        </tr>
                        <tr>
                            <td > {t('Share Difficulty')}</td>
                            <td >
                                <abbr title={t('Difficulty will automatically adjust according to your hashrate')}>{t('VarDiff')}</abbr>
                            </td>
                        </tr>
                        <tr>
                            <td >{t('Algorithm')}</td>
                            <td >{t('Ethash')}</td>
                        </tr>
                        </thead>
                    </table>
                </div>
                {t('2.Mining Software')}
                <div className={'row'}>
                    <div className={'div'}>
                        {t('For Nvidia GPU We Recommend')}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://github.com/trexminer/T-Rex/releases">{t('T-Rex')}</a>
                        {t(', For AMD GPU We Recommend')}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://github.com/trexminer/T-Rex/releases">{t('TeamRedMiner.')}</a>
                    </div>
                </div>
                3.{t('How to Connect')}
                <div className={'row'}>

                    <table className="table table2 table-bordered">
                        <thead>
                        <tr>
                            <td >{t('SERVER')}</td>
                            <td >{t('DIFFICULTY')}</td>
                            <td >{t('HOSTPORT')}</td>
                            <td >
                                <abbr title={t('Difficulty will automatically adjust according to your hashrate')}>{t('VARDIFF')}</abbr>
                            </td>
                        </tr>
                        {map.map((item, row) => (
                            <tr key={row}>
                                <td >{item.SERVICE}</td>
                                <td >{item.DIFFICULTY}</td>
                                <td >{item.HOSTPORT}</td>
                                <td ><div className={'success'}></div></td>
                            </tr>
                        ))}

                        </thead>
                    </table>
                </div>
                {t('4.Configuration')}
                <div className={'row'}>
                    <div className={'div'}>
                        {t('You must replace all values with your own.')}
                        <div className={'row configStyle'}>
                            <div className={'col-4 borderDash_Right'}>
                                <code className={'textBackground'}>
                                    {t('<YOUR_ADDRESS>')}
                                </code>
                            </div>
                            <div className={'col-8 '}>
                                {t('Your EthereumPoW address for payouts. Generate it using the official wallet or use the address of any crypto exchange.')}<br/>
                                {t('Example: ')}
                                <code className={'textBackground'}>
                                    {t('0xFb201126E7a80419c8C11C466358593CdA76981c')}
                                </code>
                            </div>
                        </div>
                        <div className={'row configStyle'}>
                            <div className={'col-4 borderDash_Right'}>

                                <code className={'textBackground'}>
                                    {t('<WORKER_NAME>')}
                                </code>
                            </div>
                            <div className={'col-8 '}>
                                {t('The name of the worker is optional for statistics. Use English letters, numbers and symbols "_" or "-" . Maximum 32 characters.')}<br/>
                                {t('Example: ')}
                                <code className={'textBackground'}>
                                    {t(' Rig1')}
                                </code>

                            </div>
                        </div>
                    </div>
                </div>
                {t('5.Example')}
                <div className={'row'}>
                    <table className="table table1 table-bordered">
                        <thead>
                        <tr>
                            <td >{t('Mining Software')}</td>
                            <td >{t('Configuration')}</td>
                        </tr>
                        <tr>
                            <td >T-Rex</td>
                            <td ><code className={'textBackground'}>{t('t-rex -a ethash -o stratum+tcp://eu.ethwmine.com:8008 -u <YOUR_ADDRESS>.<WORKER_NAME> -p x')}</code></td>
                        </tr>
                        <tr>
                            <td >TeamRedMiner</td>
                            <td ><code className={'textBackground'}>{t('teamredminer -a ethash -o stratum+tcp://eu.ethwmine.com:8008 -u <YOUR_ADDRESS>.<WORKER_NAME> -p x')}</code></td>
                        </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <GlobalSnackbar/>
        </div>
    );
};
export default Help;
