import React, {useState} from 'react'
import LanguageIcon from "@mui/icons-material/Language"
import MenuItem from '@mui/material/MenuItem';
import PropTypes from "prop-types";
import {reactLocalStorage} from 'reactjs-localstorage'
import {useTranslation} from 'react-i18next';
import {ColorButtonTranBg, ColorMenu} from "../../../utils/view";
import {LANGUAGE_TOKENID} from "../../../config/config";

const tokenId = LANGUAGE_TOKENID;
const smOptions = ['En', "中"];
const options = ['English', "中文"];
const lngOptions = ['en', 'zh'];

function LanguageBtn() {
    const {i18n} = useTranslation();
    let userProfile = reactLocalStorage.getObject(tokenId) || {};
    const language = userProfile['lng'] || i18n.language;
    const indexInit = (language === 'zh' || language === 'zh-CN' || language === 'zh-cn') ? 1 : 0;
    const [selectedIndex, setSelectedIndex] = useState(indexInit);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseProfile = index => async () => {
        if (index === selectedIndex) {
            return;
        }
        setAnchorEl(null);
        console.log("i18n", i18n)
        await i18n.changeLanguage(lngOptions[index], null)
        userProfile['lng'] = lngOptions[index]
        reactLocalStorage.setObject(tokenId, userProfile)
        setSelectedIndex(index)
    };

    return (
        <div>
            <ColorButtonTranBg
                aria-haspopup="true"
                onClick={handleClick}>
                <LanguageIcon style={{color: 'var(--text-color)'}} sx={{mr: 0.5}}/>
                <div style={{color: 'var(--text-color)'}}>{smOptions[selectedIndex].toUpperCase()}</div>
                {/*<DownIcon style={{color: 'var(--text-color)'}} sx={{ml: 1}}/>*/}
            </ColorButtonTranBg>
            <ColorMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {
                    options.map((option, index) => (
                        <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={handleCloseProfile(index)}
                            value={index}>
                            {option}
                        </MenuItem>
                    ))
                }
            </ColorMenu>
        </div>
    )
}

LanguageBtn.propTypes = {
    classes: PropTypes.object
};

export default LanguageBtn;
