import React, {useEffect, useRef, useState} from 'react'
import "./index.css"
import GlobalSnackbar from "../../view/globalSnackbar/GlobalSnackbar";
import service from "../../../config/request";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Alert} from '@mui/material';
import {getAppearanceBool} from "../../../config/useAppearance";
import {dateDiff, getDayjsYMDHM, hashrate_formatter} from "../../../utils/common";
import {
    Tabs,
    Tab,
    Typography,
    TextField,
    InputAdornment,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box, Button
} from "@mui/material";
import PropTypes from 'prop-types';
import {
    FilterAltOutlined
} from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";
import {PayoutLimitMin, PayoutLimitMax, txUrl, addressUrl, ETHWSAN} from "../../../config/config";

const echarts = require("echarts")

const Account = ({isDark}) => {
    const {t} = useTranslation();
    const [accountData, setAccountData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [hash, setHash] = useState(null);
    const [value, setValue] = useState(0);
    const [searhValue, setSearhValue] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [paymentThreshold, setPaymentThreshold] = useState('');
    const {search} = useLocation();
    const dom = useRef();

    const setIpAddressf = (event) => {
        setIpAddress(event.target.value)
    }

    const setPaymentThresholdf = (event) => {
        setPaymentThreshold(event.target.value)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const href = window.location.href;
        const api = href.substring(href.indexOf('accounts'), href.length)
        const hash = href.substring(href.indexOf('0x'), href.length)
        setHash(hash)

        function getData() {
            service({
                url: '/' + api,
                method: 'get',
            }).then(response => {
                setAccountData(response)
            }).catch(error => {
                GlobalSnackbar.showMessage(error.message, 'error')
            })
        }

        function getChart() {
            service({
                url: '/' + api + '/chart',
                method: 'get',
            }).then(response => {
                setChartData(response)
            }).catch(error => {
                // GlobalSnackbar.showMessage(error.message, 'error')
            })
        }

        getData();
        getChart();
    }, [search]);// eslint-disable-line react-hooks/exhaustive-deps

    const initChart = () => {
        if (!dom.current) {
            dom.current = echarts.init(document.getElementById('accoutChart'));
        }

        if (!chartData) {
            return
        }

        const date = ['111', '222', '333'];
        const averageHashrate = ['001', '002', '003', '004'];
        const currentHashrate = ['100', '200', '300'];
        for (let i = 0; i < chartData.chart.length; i++) {
            date.push(chartData.chart[i].timestamp);
            averageHashrate.push(parseFloat(chartData.chart[i].averageHashrate / 1000000000).toFixed(2));
            currentHashrate.push(parseFloat(chartData.chart[i].currentHashrate / 1000000000).toFixed(2));
        }

        window.addEventListener("resize", () => {
            dom.current.resize();
        });

        dom.current.clear();
        dom.current.setOption({
            legend: {
                data: ["Current Hashrate (30m)", 'Average Hashrate (3h)'],
                orient: 'horizontal',
                x: 'center',
                y: 'top',
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: '#ccc',
                borderWidth: 0,
                padding: 10,
                itemGap: 20,
                itemWidth: 20,
                itemHeight: 14,
                textStyle: {
                    color: isDark ? "rgba(255,255,255,0.9)" : "rgba(0,0,0,0.9)"
                }
            },
            tooltip: {
                trigger: 'axis',
                // showDelay: 20,
                // hideDelay: 100,
                transitionDuration: 0.3,
                backgroundColor: isDark ? "rgba(255,255,255,0.9)" : "rgba(0,0,0,0.9)",
                borderColor: '#333',
                borderRadius: 4,
                borderWidth: 0,
                padding: 5,
                axisPointer: {
                    type: 'line',
                    lineStyle: {
                        color: '#48b',
                        width: 1,
                        type: 'solid'
                    },
                    shadowStyle: {
                        width: 'auto',
                        color: 'rgba(150,150,150,0.3)'
                    }
                },
                textStyle: {
                    color: isDark ? "rgba(0,0,0,0.9)" : "rgba(255,255,255,0.9)"
                }
            },
            grid: {
                top: '10%',
                left: '1%',
                right: '5%',
                bottom: '5%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: date,
                axisLabel: {
                    show: true,
                    interval: 10,
                    formatter: function (params) {
                        const date1 = new Date(params * 1000);
                        const h = date1.getHours();
                        const mm = date1.getMinutes();
                        return (h < 10 ? "0" + h : h) + ":" + (mm < 10 ? "0" + mm : mm)
                    },
                    color: getAppearanceBool() ? "rgba(255,255,255,0.9)" : "rgba(34,34,34,1)",
                    showMinLabel: true,
                    showMaxLabel: true,
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return getDayjsYMDHM(params.value)
                        }
                    }
                },
                boundaryGap: false
            },
            yAxis: [
                {
                    type: 'value',
                    min: 0,
                    interval: 1,
                    position: 'right',
                    axisLabel: {show: false},
                    axisTick: {show: false,},
                    axisLine: {show: false,},
                    splitLine: {show: false,}
                },
                {
                    type: 'value',
                    min: 0,
                    interval: 1,
                    position: 'left',
                    axisLabel: {
                        formatter: '{value} GH/s',
                        color: isDark ? "rgba(255,255,255,0.9)" : "rgba(34,34,34,1)"
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ["rgba(204,204,204,0.3)"],
                            width: 1,
                            type: 'soild'
                        }
                    }
                },
            ],
            series: [
                {
                    name: 'Current Hashrate (30m)',
                    type: 'line',
                    yAxisIndex: 0,
                    symbol: 'none',
                    smooth: true,
                    sampling: 'none',
                    itemStyle: {color: 'rgb(255, 158, 68)'},
                    data: currentHashrate
                },
                {
                    name: 'Average Hashrate (3h)',
                    yAxisIndex: 1,
                    type: 'line',
                    symbol: 'none',
                    smooth: true,
                    sampling: 'none',
                    itemStyle: {color: 'rgb(55, 58, 168)'},
                    data: averageHashrate
                }
            ]
        })
    }

    useEffect(() => {
        if (chartData) {
            initChart();
        }
    }, [chartData, dom, isDark]);// eslint-disable-line react-hooks/exhaustive-deps

    function TabPanel(props) {
        const {children, value, index, ...other} = props;
        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
                 aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && children}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function Percentage(value, num) {
        if (value === 0 || value === null || value === undefined) {
            return 0
        }
        // eslint-disable-next-line
        let sum = eval(num.join("+"));
        return (value / sum * 100).toFixed(0)
    }

    const searh = (event) => {
        setSearhValue(event.target.value)
    };

    return (
        <div className={'mainLy'}>
            <div className={'container'} style={{marginTop: '20px'}}>
                {
                    accountData &&
                    <div>
                        <div className={'m-3 v-banner_text'}>
                            {t('Address: ')}
                            <a className={'text-monospace'} href={addressUrl + hash}
                               target={'_blank'} rel="noreferrer">
                                {hash}
                            </a>
                        </div>
                        <div>
                            <Alert className={'alert_color_success'} variant="outlined" severity="success"
                                   iconMapping={{success: <CheckCircleOutlineIcon fontSize="inherit"/>}}>
                                {t('Payment Threshold ') + PayoutLimitMin + " ETHW"}
                            </Alert>
                        </div>
                        <div>
                            <div className={'row mt-2'}>
                                <div className={'pb-2 col-sm-12 col-md-6'}>
                                    <div className={'borderColor'}>
                                        <div className={'p-2 borderDash_Bottom td_title'}>{t('Hashrate')}</div>
                                        <div className={'p-2 row td_text'}>
                                            <div className={'col-6 borderDash_Right'}>
                                                <div>{t('Current Hashrate (30m)')}</div>
                                                <div>{hashrate_formatter(accountData.currentHashrate, 2, 'H/s')}</div>
                                            </div>
                                            <div className={'col-6'}>
                                                <div>{t('Average Hashrate (3h)')}</div>
                                                <div>{hashrate_formatter(accountData.hashrate, 2, 'H/s')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'pb-2 col-sm-12 col-md-6'}>
                                    <div className={'borderColor'}>
                                        <div className={'p-2 borderDash_Bottom td_title'}>{t('Shares Stats (1H)')}</div>
                                        <div className={'p-2 row td_text'}>
                                            <div className={'col-4 borderDash_Right'}>
                                                <div>{t('Valids')}</div>
                                                <div>
                                                    {accountData.stats.shareAccept}
                                                    {'(' + Percentage(accountData.stats.shareAccept, accountData.stats.shareAccept, accountData.stats.shareStale, accountData.stats.shareInvalid) + '%)'}
                                                </div>
                                            </div>
                                            <div className={'col-4 borderDash_Right'}>
                                                <div>{t('Stale')}</div>
                                                <div>{accountData.stats.shareStale}
                                                    {'(' + Percentage(accountData.stats.shareStale, accountData.stats.shareAccept, accountData.stats.shareStale, accountData.stats.shareInvalid) + '%)'}
                                                </div>
                                            </div>
                                            <div className={'col-4'}>
                                                <div>{t('Invalid')}</div>
                                                <div>
                                                    {accountData.stats.shareInvalid}
                                                    {'(' + Percentage(accountData.stats.shareInvalid, accountData.stats.shareAccept, accountData.stats.shareStale, accountData.stats.shareInvalid) + '%)'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'pb-2 col-lg-3 col-md-6 col-sm-12'}>
                                    <div className={'borderColor'}>
                                        <div className={'p-2 borderDash_Bottom td_title'}>{t('Workers')}</div>
                                        <div className={'p-2 row td_text'}>
                                            <div className={'col-6 borderDash_Right'} style={{textAlign: 'center'}}>
                                                <div>{t('Online')}</div>
                                                <div style={{color: 'green'}}>{accountData.workersOnline}</div>
                                            </div>
                                            <div className={'col-6'} style={{textAlign: 'center'}}>
                                                <div>{t('Offline')}</div>
                                                <div style={{color: 'red'}}>{accountData.workersOffline}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'pb-2 col-lg-4 col-md-6 col-sm-12'}>
                                    <div className={'borderColor'}>
                                        <div className={'p-2 borderDash_Bottom td_title'}>{t('WORK')}</div>
                                        <div className={'p-2 row td_text'}>
                                            <div className={'col-6 borderDash_Right'}>
                                                <div>{t('Your Round Share Sum')}</div>
                                                <div>-</div>
                                            </div>
                                            <div className={'col-6'}>
                                                <div>{t('Your Round Share')}</div>
                                                <div>-</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'pb-2 col-lg-5 col-md-12'}>
                                    <div className={'borderColor'}>
                                        <div className={'p-2 borderDash_Bottom td_title'}>{t('REWARD')}</div>
                                        <div className={'p-2 row td_text'}>
                                            <div className={'col-4 borderDash_Right'}>
                                                <div>{t('Unconfirmed')}</div>
                                                <div>{(accountData.stats.immature / 1000000000).toFixed(6)}</div>
                                            </div>
                                            <div className={'col-4 borderDash_Right'}>
                                                <div>{t('Unpaid Balance')}</div>
                                                <div>{(accountData.stats.balance / 1000000000).toFixed(6)}</div>
                                            </div>
                                            <div className={'col-4'}>
                                                <div>{t('Reward (24h)')}</div>
                                                <div>{accountData['24hreward']}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    chartData &&
                    <div>
                        <div className={'mt-2 borderColor'} style={{height: 400, width: '100%'}}>
                            <div style={{height: '100%'}} id={'accoutChart'}/>
                        </div>
                    </div>
                }
                {
                    accountData &&
                    <div>
                        <div className={'mt-2 borderColor'}>
                            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                                <Tab className={'auto_color'} label={t('Workers')} {...a11yProps(0)}/>
                                <Tab className={'auto_color'} label={t('Hourly')} {...a11yProps(1)}/>
                                <Tab className={'auto_color'} label={t('Rewards')} {...a11yProps(2)}/>
                                <Tab className={'auto_color'} label={t('Payouts')} {...a11yProps(3)}/>
                                <Tab className={'auto_color'} label={t('Day Income')} {...a11yProps(4)}/>
                                <Tab className={'auto_color'} label={t('Setting')} {...a11yProps(5)}/>
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <div className={'p-3'}>
                                    <TextField id="searhValue" label={t('Filter by Worker')} variant="outlined"
                                               value={searhValue} onChange={searh}
                                               InputProps={{
                                                   startAdornment: (
                                                       <InputAdornment position="start" className={'auto_color'}>
                                                           <FilterAltOutlined/>
                                                       </InputAdornment>
                                                   ),
                                               }}/>
                                </div>
                                <div className={'borderColor_Top'}>
                                    <TableContainer>
                                        <Table sx={{minWidth: 650}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className={'table_nowrap'}>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Worker')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Current (30m)')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Average (3h)')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Valids')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Stale')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Invalid')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Server')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Last Share')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    accountData.workers &&
                                                    Object.entries(accountData.workers).map((row, index) => {
                                                        if (row[0].indexOf(searhValue) !== -1 || searhValue === '') {
                                                            return (
                                                                <TableRow key={index} className={'table_nowrap'}>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {row[0]}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {hashrate_formatter(row[1].hr, 2, 'H/s')}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {hashrate_formatter(row[1].hr2, 2, 'H/s')}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {row[1].accept}
                                                                        {'(' + Percentage(row[1].accept, row[1].accept, row[1].invalid, row[1].stale) + '%)'}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {row[1].invalid}
                                                                        {'(' + Percentage(row[1].invalid, row[1].accept, row[1].invalid, row[1].stale) + '%)'}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {row[1].stale}
                                                                        {'(' + Percentage(row[1].stale, row[1].accept, row[1].invalid, row[1].stale) + '%)'}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {row[1].server}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {dateDiff(row[1].lastBeat)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className={'borderColor_Top'}>
                                    <TableContainer>
                                        <Table sx={{minWidth: 650}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className={'table_nowrap'}>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Time')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Share Sum')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Hashrate')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Valids')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Stale')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Invalid')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Duration')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    accountData.shiftsHour &&
                                                    accountData.shiftsHour.map((row, index) => (
                                                        <TableRow key={index} className={'table_nowrap'}>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {getDayjsYMDHM(row.timestamp)}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                -
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {hashrate_formatter(row.hashrate, 2, 'H/s')}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {row.accept}
                                                                {'(' + Percentage(row.accept, row.accept, row.stale, row.invalid) + '%)'}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {row.stale}
                                                                {'(' + Percentage(row.stale, row.accept, row.stale, row.invalid) + '%)'}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {row.invalid}
                                                                {'(' + Percentage(row.invalid, row.accept + row.stale + row.invalid) + '%)'}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                -
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div className={'borderColor_Top'}>
                                    <div className={'p-3'}>
                                        <TableContainer>
                                            <table className="table table_account table-bordered">
                                                <thead>
                                                <tr>
                                                    <td>{t('Interval')}</td>
                                                    <td>{t('ETHW')}</td>
                                                    <td>{t('BTC')}</td>
                                                    <td>{t('USD')}</td>
                                                    <td>{t('RUB')}</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    accountData.sumrewards &&
                                                    accountData.sumrewards.map((row, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{row.name}</td>
                                                                <td>{row.reward}</td>
                                                                <td>{(row.reward * accountData.price.btc).toFixed(8)}</td>
                                                                <td>{(row.reward * accountData.price.usd).toFixed(2)}</td>
                                                                <td>{(row.reward * accountData.price.rub).toFixed(2)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </TableContainer>
                                    </div>
                                    <div className={'borderColor_Top'}>
                                        <TableContainer>
                                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow className={'table_nowrap'}>
                                                        <TableCell className={'auto_color'} align="left">
                                                            {t('Height')}
                                                        </TableCell>
                                                        <TableCell className={'auto_color'} align="left">
                                                            {t('Time')}
                                                        </TableCell>
                                                        <TableCell className={'auto_color'} align="left">
                                                            {t('hash')}
                                                        </TableCell>
                                                        <TableCell className={'auto_color'} align="left">
                                                            {t('Status')}
                                                        </TableCell>
                                                        <TableCell className={'auto_color'} align="left">
                                                            {t('Percent')}
                                                        </TableCell>
                                                        <TableCell className={'auto_color'} align="left">
                                                            {t('Reward')}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        accountData.rewards &&
                                                        accountData.rewards.map((row, index) => (
                                                            <TableRow key={index} className={'table_nowrap'}>
                                                                <TableCell className={'auto_color'} align="left">
                                                                    <a className={'text-monospace'}
                                                                       href={ETHWSAN + row.hash}
                                                                       rel="noreferrer" target={'_blank'}>
                                                                        {row.height}
                                                                    </a>
                                                                </TableCell>
                                                                <TableCell className={'auto_color'} align="left">
                                                                    {getDayjsYMDHM(row.timestamp)}
                                                                </TableCell>
                                                                <TableCell className={'auto_color'} align="left">
                                                                    <a className={'text-monospace'}
                                                                       style={{fontFamily: 'Consolas,Monaco,monospace'}}
                                                                       href={ETHWSAN + row.hash}
                                                                       rel="noreferrer" target={'_blank'}>
                                                                        {row.hash.slice(0, 16)}...{row.hash.slice(row.hash.length - 16, row.hash.length)}
                                                                    </a>
                                                                </TableCell>
                                                                <TableCell className={'auto_color'} align="left">
                                                                    {row.immature ? t('Confirmed') : t('Pending')}
                                                                </TableCell>
                                                                <TableCell className={'auto_color'} align="left">
                                                                    {(row.percent * 100).toFixed(6)}%
                                                                </TableCell>
                                                                <TableCell className={'text_nowrap'}
                                                                           style={{color: 'green'}} align="left">
                                                                    {(row.reward / 1000000000).toFixed(6)}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <div className={'borderColor_Top'}>
                                    <TableContainer>
                                        <Table sx={{minWidth: 650}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className={'table_nowrap'}>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Time')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('Amount')}
                                                    </TableCell>
                                                    <TableCell className={'auto_color'} align="left">
                                                        {t('TX')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    accountData.payments &&
                                                    accountData.payments.map((row, index) => (
                                                        <TableRow key={index} className={'table_nowrap'}>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {getDayjsYMDHM(row.timestamp)}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {(row.amount / 1000000000).toFixed(6)}
                                                            </TableCell>
                                                            <TableCell className={'text_nowrap'}
                                                                       style={{color: 'green'}} align="left">
                                                                <a className={'text-monospace'} href={txUrl + row.tx}
                                                                   style={{fontFamily: 'Consolas,Monaco,monospace'}}
                                                                   rel="noreferrer" target={'_blank'}>
                                                                    {row.tx}
                                                                </a>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'borderColor_Top'}>
                                            <TableContainer>
                                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow className={'table_nowrap'}>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {t('Time')}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {t('ETHW')}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {t('btc')}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {t('usd')}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {t('rub')}
                                                            </TableCell>
                                                            <TableCell className={'auto_color'} align="left">
                                                                {t('Duration')}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            accountData.paymentDay &&
                                                            accountData.paymentDay.map((row, index) => (
                                                                <TableRow key={index} className={'table_nowrap'}>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {getDayjsYMDHM(row.timestamp)}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {row.amount}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {(row.amount * accountData.price.btc).toFixed(8)}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {(row.amount * accountData.price.usd).toFixed(2)}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        {(row.amount * accountData.price.rub).toFixed(2)}
                                                                    </TableCell>
                                                                    <TableCell className={'auto_color'} align="left">
                                                                        -
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'m-3 borderColor'} style={{maxWidth: 500}}>
                                            <div className={'borderColor_Bottom'}>
                                                <Typography className={'p-3'} variant="h6" gutterBottom>
                                                    {t('Payout setting')}
                                                </Typography>
                                            </div>
                                            <Box className={'pt-2 borderColor_Bottom'} component="form"
                                                 noValidate autoComplete="off"
                                                 sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}>
                                                <div className={'pt-2 ps-3'}>
                                                    <TextField style={{width: '95%', margin: 0}} required
                                                               id="outlined-required" label={t('Address')}
                                                               defaultValue={hash} disabled/>
                                                </div>
                                                <div className={'pt-2 ps-3'}>
                                                    <TextField style={{width: '95%', margin: 0}} required
                                                               id="outlined-required" value={ipAddress}
                                                               onChange={setIpAddressf}
                                                               label={t("Your worker's IP address")}/>
                                                </div>
                                                <div className={'pt-2 ps-3'}>
                                                    <TextField style={{width: '95%', margin: 0}} required
                                                               id="outlined-required" value={paymentThreshold}
                                                               onChange={setPaymentThresholdf}
                                                               label={t('Payment Threshold')}/>
                                                </div>
                                                <div className={'ps-3 pb-3'}>
                                                    <Typography variant="caption" gutterBottom>
                                                        {
                                                            t('Min: ') +
                                                            PayoutLimitMin +
                                                            ' ETHW, ' +
                                                            t('Max: ') +
                                                            PayoutLimitMax +
                                                            ' ETHW'
                                                        }
                                                    </Typography>
                                                </div>
                                            </Box>
                                            <div className={'p-3'} style={{textAlign: 'right'}}>
                                                <Button variant="outlined" className={'auto_color border_color'}>
                                                    {t('Save')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                }
            </div>
            <GlobalSnackbar/>
        </div>
    );
};
export default Account;

